import i18next from '../i18n';

const CATEGORIES = [
  {
    name: 'Restauration rapide',
    values: [
      'Pizzeria',
      'Burgers',
      'Salades',
      'Sandwichs',
      'Snack',
      'Tacos',
      'Desserts',
      'Foccacias',
      'Boissons',
      'En-cas',
      'Apèro',
      'Café',
      'Glaces',
      'Crêpes',
    ],
  },
  {
    name: 'Restaurant',
    values: [
      'Traditionnel',
      'Classique',
      'Raffiné',
      'Indien',
      'Asiatique',
      'Italien',
      'Végétarien',
      'Sushis',
      'Mexicain',
      'Healthy',
      'Méditerranéen',
      'Oriental',
      'Brunch',
    ],
  },
  {
    name: 'Commerce',
    values: ['Supermarché', 'Boulangerie', 'Épicerie', 'Patisserie'],
  },
];

const emotes = [
  {
    name: 'Pizzeria',
    icon: '🍕',
  },
  {
    name: 'Burgers',
    icon: '🍔',
  },
  {
    name: 'Salades',
    icon: '🥗',
  },
  {
    name: 'Sandwichs',
    icon: '🥪',
  },
  {
    name: 'Snack',
    icon: '🍟',
  },
  {
    name: 'Tacos',
    icon: '🌯',
  },
  {
    name: 'Desserts',
    icon: '🍪',
  },
  {
    name: 'Foccacias',
    icon: '🇮🇹',
  },
  {
    name: 'Boissons',
    icon: '🥤',
  },
  {
    name: 'En-cas',
    icon: '🫔',
  },
  {
    name: 'Apèro',
    icon: '🥂',
  },
  {
    name: 'Café',
    icon: '☕',
  },
  {
    name: 'Glaces',
    icon: '🍦',
  },
  {
    name: 'Crêpes',
    icon: '🥞',
  },
  {
    name: 'Traditionnel',
    icon: '🫕',
  },
  {
    name: 'Classique',
    icon: '🥘',
  },
  {
    name: 'Raffiné',
    icon: '🍲',
  },
  {
    name: 'Indien',
    icon: '🍛',
  },
  {
    name: 'Asiatique',
    icon: '🍱',
  },
  {
    name: 'Italien',
    icon: '🍝',
  },
  {
    name: 'Végétarien',
    icon: '🥗',
  },
  {
    name: 'Sushis',
    icon: '🍣',
  },
  {
    name: 'Supermarché',
    icon: '🛒',
  },
  {
    name: 'Boulangerie',
    icon: '🥖',
  },
  {
    name: 'Épicerie',
    icon: '🥐',
  },
  {
    name: 'Patisserie',
    icon: '🧁',
  },
  {
    name: 'Mexicain',
    icon: '🇲🇽',
  },
  {
    name: 'Healthy',
    icon: '💪',
  },
  {
    name: 'Méditerranéen',
    icon: '🧆',
  },
  {
    name: 'Oriental',
    icon: '🥙',
  },
  {
    name: 'Brunch',
    icon: '🍳',
  },
];

export const CATEGORIES_DISPLAYABLE = CATEGORIES.reduce((acc, category) => {
  return [
    ...acc,
    ...category.values.map(value => ({
      name: value,
      icon: emotes.find(emote => emote.name === value).icon,
    })),
  ];
}, []);

export const getCategoryIcon = c =>
  CATEGORIES_DISPLAYABLE.find(category => category.name === c)?.icon;

export default CATEGORIES;
