import isEqual from 'lodash/isEqual';

export function checkIfItemIsSame(item1, item2) {
  if (!!item1.item.isMenu || !!item2.item.isMenu) {
    return false;
  }

  const item1Options = item1.options;
  const item2Options = item2.options;
  const item1Supplements = item1.supplements;
  const item2Supplements = item2.supplements;
  const isSupplementsEqual = isEqual(item1Supplements, item2Supplements);
  const isOptionsEqual = isEqual(item1Options, item2Options);
  const isPriceEqual = item1.price === item2.price;
  const isProductEqual = item1.item.id === item2.item.id;

  return isProductEqual && isPriceEqual && isSupplementsEqual && isOptionsEqual;
}

export function sortedUniqItemsWithCount(items) {
  const uniqItems = items.reduce((acc, item) => {
    const existingItem = acc.find(i => checkIfItemIsSame(i.item, item));
    if (existingItem) {
      existingItem.count++;
    } else {
      acc.push({ item, count: 1 });
    }
    return acc;
  }, []);

  return uniqItems.sort((a, b) => {
    if (a.item.item.isMenu) return -1;
    if (b.item.item.isMenu) return 1;
    if (a.item.supplements.length === 0 && b.item.supplements.length > 0)
      return 1;
    if (a.item.supplements.length > 0 && b.item.supplements.length === 0)
      return -1;
    if (a.item?.options?.length === 0 && b.item?.options?.length > 0) return 1;
    if (a.item?.options?.length > 0 && b.item?.options?.length === 0) return -1;
    return a.item.item.name.localeCompare(b.item.item.name);
  });
}
