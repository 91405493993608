import React from 'react';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import {
  LineWrapper,
  ItemContent,
  ItemHeader,
  MenuDetails,
  MenuDetailsItem,
  ItemDetails,
  ItemFooter,
} from '../../../../styles/basket';
import { Text, Heading } from '../../../../styleguide';
import { SPACING } from '../../../../styles/constants';
import { parseNumber } from '../../../../common';
import { useTheme } from '../../../../hooks';

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 0;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;

  h2 {
    margin: 0;
    font-size: 28px;
  }
`;

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  color: white;
  padding: 8px;
  margin: 8px 0;

  h1 {
    margin: 0;
  }
`;

const ItemsWrapper = styled.div`
  padding: 0 8px;
  margin: 0;
`;

const TotalsWrapper = styled.div`
  width: 100%;
  padding: 0 8px;
  margin: 0;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TotalRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const InlineFlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  padding: 0;
`;

const orderedOptions = options => {
  return orderBy(options, i => i.order, 'asc');
};

const ProductLine = props => {
  const { shopItem, lg, count = 1, withPrice = true } = props;
  const theme = useTheme();

  return (
    <LineWrapper>
      <ItemContent>
        <ItemHeader>
          <Heading
            size={lg ? 800 : 600}
            styles={{
              color: 'black',
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            x{count} {shopItem.item.name}
          </Heading>
        </ItemHeader>
        <ItemDetails>
          {!!shopItem.options?.length &&
            orderedOptions(shopItem.options).map((opt, i) => (
              <InlineFlexRow
                style={{
                  padding: `0 ${SPACING[100]}`,
                }}
              >
                <Heading
                  size={lg ? 800 : 600}
                  key={`${opt.name}-${i}`}
                  styles={
                    i < opt.items.length - 1
                      ? { marginRight: SPACING[100], color: 'black' }
                      : { color: 'black' }
                  }
                >
                  {opt.name} :{' '}
                  {opt.items.map((item, i) => (
                    <Heading
                      is="span"
                      size={lg ? 800 : 600}
                      key={`${item.name}-${i}`}
                      muted
                      styles={{
                        color: 'black',
                        fontWeight: 'bold',
                        paddingLeft: `${SPACING[200]}`,
                      }}
                    >
                      {item.name} {i < opt.items.length - 1 && ' / '}
                    </Heading>
                  ))}
                </Heading>
              </InlineFlexRow>
            ))}
          {shopItem.supplements && shopItem.supplements.length ? (
            <InlineFlexRow
              style={{
                padding: `0 ${SPACING[100]}`,
              }}
            >
              <Heading size={lg ? 800 : 600} styles={{ color: 'black' }}>
                Suppléments :{' '}
                {shopItem.supplements.map((supp, i) => (
                  <Heading
                    is="span"
                    size={lg ? 800 : 600}
                    muted
                    styles={{ color: 'black', fontWeight: 'bold' }}
                  >
                    {supp.name} {i < shopItem.supplements.length - 1 && ' / '}
                  </Heading>
                ))}
              </Heading>
            </InlineFlexRow>
          ) : null}
        </ItemDetails>
      </ItemContent>
      {withPrice && (
        <ItemFooter flexEnd>
          <Text
            style={{ color: theme.headingColor, fontWeight: 700 }}
            size={500}
          >
            {parseNumber(shopItem.price).toFixed(2)}
            {' €'}
          </Text>
        </ItemFooter>
      )}
    </LineWrapper>
  );
};

const MenuLine = props => {
  const { shopItem, lg, count = 1, withPrice = true } = props;
  const theme = useTheme();

  return (
    <LineWrapper>
      <ItemContent>
        <ItemHeader>
          <Heading
            size={lg ? 800 : 600}
            styles={{
              color: 'black',
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            x{count} Menu: {shopItem.item.name}
          </Heading>
        </ItemHeader>
        <MenuDetails withoutPadding>
          {shopItem.products.map((product, index) => (
            <MenuDetailsItem key={index}>
              <Heading
                size={lg ? 800 : 600}
                style={{
                  margin: '4px 0',
                  color: 'black',
                  textDecoration: 'underline',
                }}
              >
                - {product.item.name}
              </Heading>
              {!!product.options?.length &&
                orderedOptions(product.options).map((opt, i) => (
                  <InlineFlexRow
                    style={{
                      padding: `0 ${SPACING[100]}`,
                    }}
                  >
                    <Heading
                      size={lg ? 800 : 600}
                      key={`${opt.name}-${i}`}
                      styles={
                        i < opt.items.length - 1
                          ? { marginRight: SPACING[100], color: 'black' }
                          : { color: 'black' }
                      }
                    >
                      {opt.name} :{' '}
                      {opt.items.map((item, i) => (
                        <Heading
                          is="span"
                          size={lg ? 800 : 600}
                          muted
                          key={`${item.name}-${i}`}
                          styles={{ color: 'black', fontWeight: 'bold' }}
                        >
                          {item.name} {i < opt.items.length - 1 && ' / '}
                        </Heading>
                      ))}
                    </Heading>
                  </InlineFlexRow>
                ))}
              {!!product.supplements?.length && (
                <InlineFlexRow
                  style={{
                    padding: `0 ${SPACING[100]}`,
                  }}
                >
                  <Heading size={lg ? 800 : 600} styles={{ color: 'black' }}>
                    Suppléments :{' '}
                    {product.supplements.map((supp, i) => (
                      <Heading
                        is="span"
                        size={lg ? 800 : 600}
                        muted
                        styles={{ color: 'black', fontWeight: 'bold' }}
                      >
                        {supp.name}{' '}
                        {i < product.supplements.length - 1 && ' / '}
                      </Heading>
                    ))}
                  </Heading>
                </InlineFlexRow>
              )}
            </MenuDetailsItem>
          ))}
        </MenuDetails>
      </ItemContent>
      {withPrice && (
        <ItemFooter flexEnd>
          <Text
            style={{ color: theme.headingColor, fontWeight: 700 }}
            size={500}
          >
            {parseNumber(shopItem.price).toFixed(2)}
            {' €'}
          </Text>
        </ItemFooter>
      )}
    </LineWrapper>
  );
};

export {
  ProductLine,
  MenuLine,
  Wrapper,
  Header,
  NameWrapper,
  ItemsWrapper,
  TotalsWrapper,
  TotalRow,
  InlineFlexRow,
};
