import React from 'react';
import orderBy from 'lodash/orderBy';
import {
  LineWrapper,
  ItemImage,
  ItemContent,
  ItemHeader,
  MenuDetails,
  MenuDetailsItem,
  InlineFlexRow,
} from '../../styles/basket';
import Heading from '../heading';
import { useTheme } from '../../hooks';
import { SPACING } from '../../styles/constants';
import { Text } from '../../styleguide';

const MenuLine = props => {
  const { shopItem, withoutImg, count = 1 } = props;
  const theme = useTheme();

  const orderedOptions = options => {
    return orderBy(options, i => i.order, 'asc');
  };

  return (
    <LineWrapper>
      {!withoutImg && (
        <ItemImage
          w="60px"
          h="40px"
          imageSRC={shopItem.item.pictureURL}
          theme={theme}
        />
      )}

      <ItemContent>
        <ItemHeader>
          <Heading
            size={500}
            styles={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!!withoutImg ? (
              <span
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  marginRight: '4px',
                }}
              >
                {'• '}
              </span>
            ) : (
              ''
            )}
            {`${count}x ${shopItem.item.name}`}
          </Heading>
        </ItemHeader>
        <MenuDetails withoutPadding paddingLeft={!!withoutImg}>
          {shopItem.products.map((product, index) => (
            <MenuDetailsItem key={index}>
              <Text
                size={400}
                style={{
                  margin: '4px 0',
                  color: theme.headingColor,
                  textDecoration: 'underline',
                }}
              >
                {product.item.name}
              </Text>
              {!!product.options?.length &&
                orderedOptions(product.options).map((opt, i) => (
                  <InlineFlexRow
                    otherStyles={`
                      padding: 0 ${SPACING[100]};
                    `}
                  >
                    <Text
                      size={300}
                      key={`${opt.name}-${i}`}
                      styles={
                        i < opt.items.length - 1
                          ? { marginRight: SPACING[100] }
                          : {}
                      }
                    >
                      {opt.name} :{' '}
                      {opt.items.map((item, i) => (
                        <Text
                          is="span"
                          size={300}
                          styles={{
                            color: theme.headingColor,
                            fontWeight: 'bold',
                          }}
                          key={`${item.name}-${i}`}
                        >
                          {item.name} {i < opt.items.length - 1 && ' / '}
                        </Text>
                      ))}
                    </Text>
                  </InlineFlexRow>
                ))}
              {!!product.supplements?.length && (
                <InlineFlexRow
                  otherStyles={`
                  padding: 0 ${SPACING[100]};
                `}
                >
                  <Text size={300}>
                    Suppléments :{' '}
                    {product.supplements.map((supp, i) => (
                      <Text
                        is="span"
                        size={300}
                        styles={{
                          color: theme.headingColor,
                          fontWeight: 'bold',
                        }}
                      >
                        {supp.name}{' '}
                        {i < product.supplements.length - 1 && ' / '}
                      </Text>
                    ))}
                  </Text>
                </InlineFlexRow>
              )}
            </MenuDetailsItem>
          ))}
        </MenuDetails>
      </ItemContent>
    </LineWrapper>
  );
};

export default MenuLine;
